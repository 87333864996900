import React, { useState, useRef,useReducer, useEffect } from 'react'
import { Button, Modal, Space, Toast, Divider,ImageViewer } from 'antd-mobile'
import AForm from './component/AForm/AForm';
import './App.css';
import { Navigate, useLocation,useNavigate} from 'react-router-dom'



export function useQuery() {
    const { search } = useLocation();
   
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const initstate = ""


export interface ITxContext {
  state:string;
  dispatch:Function
}

export interface Action {
  type:'get_yzm';
  value:any
}

// const text1 = "text1"

// const text2 = "text2"
const Aform = <AForm />

const reducer = (state : typeof  initstate,action :Action ):any =>{
  switch(action.type){
    case "get_yzm" :
      return action.value 
    default:
      return "state"
  }
}
export const TxContext = React.createContext({})
function App() {
  const [imgurls,setImgurls] = useState([""])
  const [fid,setFid] = useState("")
  const [visible, setVisible] = useState(true)
  const [visible1, setVisible1] = useState(false)
 const [state,dispatch] = useReducer(reducer,initstate)

 let query = useQuery()
 useEffect(()=>{
  console.log(query.get("fid"));


 },[])
 
  
//  const imgs = imgurls.map((imgurl:string)=>
//  <Image src={imgurl} className='Img'></Image> )

  return (
    <TxContext.Provider value={{state,dispatch}}>
    <div className="App">
      <Modal visible={visible}
        title="验证"
        content={Aform}
        actions={[{key: 'confirm',
        text: '确定',onClick:()=>{
          console.log(state)

          if (state == "12108143" && query.get("fid")=="f200000200100"){
          setVisible(false)
          setVisible1(true)
          setImgurls(["http://mrscdn.amcellmed.com/fanans/%E5%BE%90%E8%8A%AC.png",
          "http://mrscdn.amcellmed.com/fanans/%E5%BE%90%E8%8A%AC2.png",
        "http://mrscdn.amcellmed.com/fanans/%E5%BE%90%E8%8A%AC3.png",
        "http://mrscdn.amcellmed.com/fanans/%E5%BE%90%E8%8A%AC4.png",
        "http://mrscdn.amcellmed.com/fanans/%E5%BE%90%E8%8A%AC5.png"])
        }else if (state == "10197540" ){
          setVisible(false)
          setVisible1(true)
          setImgurls(["http://mrscdn.amcellmed.com/fanans/%E9%82%93%E4%BF%8A1.png","http://mrscdn.amcellmed.com/fanans/%E9%82%93%E4%BF%8A2.jpg"])
        }else {
          Toast.show({ content: '验证码错误' })
        }
        }}]}
        >
        
      </Modal>
    
      <ImageViewer.Multi
        images={imgurls}
        visible={visible1}
        defaultIndex={1}
        onClose={() => {
          setVisible(false)
        }}
      />
    
       
    </div>
    </TxContext.Provider>
  );
}

export default App;
