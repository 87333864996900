
import  React,{useContext,useEffect,useState} from "react";
import { Input} from 'antd-mobile'
import { TxContext,ITxContext } from "../../App";





export default () => {
    // const [sdata,setSdata] = useState("") 
    const { state,dispatch } = useContext<{}>(TxContext) as ITxContext
   
 
    const Ionchange = (val:string)=>{
        dispatch({type:"get_yzm",value:val})
    }    
      
   

     

     return(
         <>
         <Input 
            placeholder="请输入验证码"
            onChange={Ionchange}
         ></Input>
         </>
     )


}